//
// https://vuetifyjs.com/en/getting-started/installation/#webpack-install
// guidance around using as plugin
//
import 'stylesheets/portal_vuetify.scss'
import 'nouislider/distribute/nouislider.css';

import Vue from 'vue'
import Vuetify from 'vuetify'
// import 'vuetify/dist/vuetify.min.css' - we are using our own copy of vuetify sass from src

Vue.use(Vuetify)

const opts = {
  theme: { disable: true },
};

export default new Vuetify(opts)
