<template>
  <v-app id="authentication_layout">

    <slot name="header" v-if="headerOn">
    </slot>

    <v-main class="grey lighten-4">
      <v-container fill-height fluid>
        <v-row align="center"
               justify="center">
            <slot name="main">
            </slot>
            <slot>
              <v-card min-height="50vh"> </v-card>
            </slot>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  props: {
    headerOn:{
      type: Boolean,
      default: false
    }
  }
};
</script>
