import Vue from 'vue'
import vuetify from "plugins/portal_vuetify"; // exports new instance
import 'stylesheets/portal'

import AuthenticationLayout from 'components/portal/layout/AuthenticationLayout';
import Header from "components/portal/layout/Header";
import InlinePlaceholder from "components/portal/layout/InlinePlaceholder";

function mountLayout() {
    const authLayoutPlaceholder = document.getElementById("vue_wrapper");
    if (!authLayoutPlaceholder) return;

    const _metadata = Object.assign({}, authLayoutPlaceholder.dataset);
    const _props = JSON.parse(_metadata.props || {});
    const {branding, user } = _props;

    let vm = new Vue({
        el: authLayoutPlaceholder,
        vuetify,
        components: {
            AuthenticationLayout
        },
        render: (h) => {
            return h(AuthenticationLayout, {
                    props: { user: user },
                    scopedSlots: {
                        default: () => h('div', {domProps: {innerHTML: authLayoutPlaceholder.innerHTML}}),
                        main: () => h(InlinePlaceholder("[data-placeholder=vue][data-slot=main]")),
                        header: () => h(Header, {props: {branding: branding, user: user}}),
                    }
                }
            )
        }
    });
    global.vm = vm;
}

packLoader(mountLayout)
