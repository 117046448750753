<template>
  <div class="user-menu-container">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="tw-normal-case"
               color="purple darken-4"
               v-bind="attrs"
               v-on="on"
               rounded
               outlined
               data-qa-id="username_button">
          {{ user.email }}
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list data-qa-id="user_menu">
        <v-list-item
            v-for="(navItem, index) in user.navigation"
            :key="index"
            :href="navItem.url"
            :data-qa-id="navItem.data_cypress">
          <v-list-item-title>{{ navItem.caption }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  props:{
    user: {
      type: Object,
      default(){
        return {
          name: "",
          navigation: [
            { caption: "Sign Out", url: "#"}
          ]
        }
      }
    }
  },
  data: () => ({

  }),
}
</script>