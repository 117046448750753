<template>
    <v-app-bar
            app
            clipped-left
            height="100px"
            extension-height="117"
            flat
    >
        <v-sheet width="100%"
                 height="100"
                 elevation="4"
                 class="pl-15 py-5 pr-8"

        >
            <!-- Branding section -->
            <div id="branding" class="d-inline-flex float-left">
                <v-img
                        v-if="branding && branding.clientLogoUrl"
                        contain
                        class="client-logo"
                        :src="branding.clientLogoUrl"
                ></v-img>
                <v-divider
                        v-if="branding && branding.clientLogoUrl"
                        vertical
                        class="mx-4"
                ></v-divider>
                <PoweredByKIPULogo :branding="branding" v-if="$vuetify.breakpoint.lgAndUp"/>
            </div>

            <div class="float-right tw-flex tw-space-x-4 tw-mt-3">
                <MessageEnvelopeWrapper
                        :is-e-m-r-origin="false"
                        v-if="isMessageEnvelopeVisible"
                        href="/portal/messages"
                        :currentUserParticipantType="currentUserParticipantType"
                        :currentUserId="currentUserId"
                        :authToken="authToken"
                        :websocketUrl="websocketUrl"
                        :instanceId="instanceId"></MessageEnvelopeWrapper>
                <div class="hidden-sm-and-down">
                    <user-menu :user=user></user-menu>
                </div>
            </div>

            <div class="user-toolbar float-right mr-2">
                <user-toolbar v-if="showToolbar"></user-toolbar>
            </div>

        </v-sheet>

        <template v-slot:extension v-if="hasMultiplePatients">
            <expanded-header :grouped_patients="user.grouped_patients"></expanded-header>
        </template>

    </v-app-bar>
</template>

<script>
import UserMenu from "./UserMenu";
import UserToolbar from "./UserToolbar";
import ExpandedHeader from "./ExpandedHeader";
import MessageEnvelopeWrapper from "components/messaging/MessageEnvelopeWrapper";
import PoweredByKIPULogo from "components/portal/layout/PoweredByKIPULogo";

export default {
    name: "Header",
    props: {
        user: {
            type: Object,
            require: true
        },
        showToolbar: {
            type: Boolean,
            default: false
        },
        isMasterInstance: {
            type: Boolean,
            require: true
        },
        isMessagesEnabled: {
            type: Boolean,
            require: true
        },
        hasAnyMessages: {
            type: Boolean,
            require: true
        },
        branding: {
            type: Object,
            default() {
                return {
                    title: "Patient Portal",
                    url: "#",
                    clientLogoUrl: "https://via.placeholder.com/115x58",
                    sponsoredLogoUrl: "https://via.placeholder.com/111x28"
                }
            }
        },
        currentUserParticipantType: {
            type: String,
            required: true,
        },
        currentUserId: {
            type: Number,
            required: true,
        },
        websocketUrl: {
            type: String,
            required: true,
        },
        instanceId: {
            type: String,
            required: true,
        },
        authToken: {
            type: String
        }
    },
    components: {PoweredByKIPULogo, MessageEnvelopeWrapper, UserToolbar, UserMenu, ExpandedHeader},
    computed: {
        hasMultiplePatients() {
            return this.user.has_multiple_patients || false;
        },
        isMessageEnvelopeVisible() {
            return this.isMessagesEnabled || this.hasAnyMessages
        }
    },
}
</script>