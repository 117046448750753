<template>
  <v-sheet width="100%" color="grey lighten-4 " class="tw-mx-10"  >
    <v-tabs align-with-title
            background-color="grey lighten-4"
            icons-and-text
            v-model="selected"
            data-qa-id="tabs_groupedPatients"
          >
      <grouped-patient-tab
          v-for="groupedPatient in groupedPatients"
          :key="groupedPatient.id"
          :href="groupedPatient.url"
          :grouped-patient="groupedPatient"
      ></grouped-patient-tab>
    </v-tabs>
    <v-divider></v-divider>
  </v-sheet>
</template>

<script>
import GroupedPatientTab from "./GroupedPatientTab";
export default {
  name: "ExpandedHeader",
  components:{ GroupedPatientTab },
  props: {
    grouped_patients: {
      type: Array,
      default() {
        return [];
      }
    },
  },
  data(){
    return {
        groupedPatients: [...this.grouped_patients],
        selected: null
    }
  },
  created(){
    let _selected = this.groupedPatients.find(p => p.selected );
    if(_selected){
      this.selected = _selected.url;
    }
  }
}
</script>