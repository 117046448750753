<template>
  <div class="user-toolbar-container">
    <v-btn class="mx-2"
           elevation="2"
           fab
           small
           dark

    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-btn class="mx-2"
           icon
           color="purple darken-4"
           outlined
           x-small
    >
      <v-icon>mdi-help</v-icon>
    </v-btn>

    <v-btn class="mx-2"
           outlined
           rounded
           color="purple darken-4"
           small
    >
      <v-icon>mdi-bell</v-icon>
      <span>2</span>
    </v-btn>
  </div>
</template>
<script>
export default {
  data: () => ({}),
}
</script>