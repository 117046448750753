<template>
    <div class="portal-logo d-flex flex-column">
        <a :href="branding.url"
           class="portal-title text-decoration-none purple--text text--darken-4">{{ branding.title }}</a>
        <v-img
                class="kipu-sponsored-logo"
                contain
                :src="branding.sponsoredLogoUrl"
        ></v-img>
    </div>
</template>
<script>
export default {
    name: 'PoweredByKIPULogo',
    props: {
        branding: {}
    }
}
</script>