<template>
  <v-tab v-if="hasMultiplePatients" :href="groupedPatient.url" :data-qa-id="metaId">
    <v-menu
        bottom
        left
        offset-y
        open-on-click
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="tw-flex tw-text-left"
             v-bind="attrs"
             v-on="on"
             @click="menuClicked">
          <div>
            <v-icon :class="cssIcon(groupedPatient)" color="blue darken-3"> mdi-circle-medium</v-icon>
          </div>
          <div class="tw-flex tw-flex-col">
            <span class="text-h4"><b>{{ groupedPatient.name }}</b></span>
            <span><small>{{ groupedPatient.description }}</small></span>
          </div>
          <div>
            <v-icon> mdi-menu-down</v-icon>
          </div>
        </div>
      </template>
      <v-tabs vertical icons-and-text v-model="selected">
        <v-tab v-for="patient in groupedPatient.patients"
               :key="patient.id"
               :href="patient.url"
               class="tw-pt-2"
        >
          <div class="tw-flex tw-text-left">
            <div>
              <v-icon :class="cssIcon(patient)" color="blue darken-3"> mdi-circle-medium</v-icon>
            </div>
            <div class="tw-flex tw-flex-col">
              <span class="text-h4"><b>{{ patient.name }}</b></span>
              <span><small>{{ patient.description }}</small></span>
            </div>
          </div>
        </v-tab>
      </v-tabs>
    </v-menu>
  </v-tab>
  <v-tab
      v-else
      :href="groupedPatient.url"
      :data-qa-id="metaId"
  >
    <div class="tw-flex tw-text-left">
      <div>
        <v-icon :class="cssIcon(groupedPatient)" color="blue darken-3"> mdi-circle-medium</v-icon>
      </div>
      <div class="tw-flex tw-flex-col">
        <span class="text-h4"><b>{{ groupedPatient.name }}</b></span>
        <span><small>{{ groupedPatient.description }}</small></span>
      </div>
    </div>
  </v-tab>
</template>

<script>
export default {
  name: "GroupedPatientTab",
  props: {
    groupedPatient: {
      type: Object,
      default() {
        return {
          id: 0,
          name: "",
          description: "",
          url: "",
          selected: false,
          has_pending_items: false,
          patients: []
        }
      }
    },
    href: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      selected: null,
      value: this.groupedPatient.url
    }
  },
  created() {
    let _selected = this.groupedPatient.patients.find(p => p.selected);
    if (_selected) {
      this.selected = _selected.url;
    }
  },
  computed: {
    hasMultiplePatients() {
      return (this.groupedPatient.patients && this.groupedPatient.patients.length > 1);
    },
    cssActive() {
      return {
        'v-tab--active': this.groupedPatient.selected
      }
    },
    metaId(){
      let desc = this.hasMultiplePatients ? "multiple" : "single";
      return `tab_patientGroup_${desc}_${this.groupedPatient.id}`;
    }
  },
  methods: {
    cssIcon(item) {
      return {
        'tw-invisible': !item.has_pending_items
      }
    },
    menuClicked(event) {
      event.preventDefault()
    }
  }
}
</script>

